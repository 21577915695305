import { render, staticRenderFns } from "./transportationPriceAdjustment_nx.vue?vue&type=template&id=9b449628&scoped=true"
import script from "./transportationPriceAdjustment_nx.vue?vue&type=script&lang=js"
export * from "./transportationPriceAdjustment_nx.vue?vue&type=script&lang=js"
import style0 from "./transportationPriceAdjustment_nx.vue?vue&type=style&index=0&id=9b449628&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b449628",
  null
  
)

export default component.exports